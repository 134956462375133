




















































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { ResourceTag } from '@/store/modules/resource'
import { EventBus } from '@/utils/event-bus'

@Component
export default class BulkAddResources extends Vue {
  selectedResourceTypeKey: string | null = null
  selectedMarketKeys: string[] = []
  selectedProductKeys: string[] = []
  selectedResourceTags: string[] = []
  files: any = null

  get resourceTypeOptions () {
    return this.$store.getters.getResourceTypes
  }

  get allResourceTagCategories () {
    return this.$store.getters.getResourceTagCategories
  }

  get allResourceTags () {
    return this.$store.getters.getResourceTags
  }

  getResourceTagsForCategory (categoryKey: string) {
    return this.allResourceTags.filter((resourceTag: ResourceTag) => {
      return resourceTag.resourceTagCategory.key === categoryKey
    })
  }

  get marketOptions () {
    return this.$store.getters.getMarkets
  }

  get productOptions () {
    return this.$store.getters.getProducts
  }

  resourceTypeChanged (key: string) {
    // if (!key) {
    //   this.resource.resourceType = null
    //   return
    // }
    // this.resource.resourceType = this.resourceTypeOptions.find((rt: ResourceType) => {
    //   return rt.key === key
    // })
  }

  marketsChanged () {
    // this.resource.markets = this.marketOptions.filter((m: Market) => {
    //   return this.selectedMarketKeys.includes(m.key)
    // })
  }

  productsChanged () {
    // this.resource.products = this.productOptions.filter((p: Product) => {
    //   return this.selectedProductKeys.includes(p.key)
    // })
    // this.buildResourceNumbers()
  }

  resourceTagsChanged () {
    // this.resource.resourceTags = this.allResourceTags.filter((rt: ResourceTag) => {
    //   return this.selectedResourceTags.includes(rt.key)
    // })
  }

  filesChosen () {
    // this.resource.filename = this.file.name
  }

  @Emit('resourcesAdded')
  resourcesAdded (keys: string[]) {

  }

  async confirmAddResources () {
    this.$bvModal.msgBoxConfirm('Confirm new resources?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      const _newResourcesConfig = {
        files: this.files,
        resourceType: this.selectedResourceTypeKey,
        resourceTags: this.selectedResourceTags,
        products: this.selectedProductKeys,
        markets: this.selectedMarketKeys
      }
      EventBus.$emit('show-loader')
      this.$store.dispatch('bulkAddResources', _newResourcesConfig).then((newResourceKeys: string[]) => {
        this.resourcesAdded(newResourceKeys)
        EventBus.$emit('hide-loader')
      })
    })
  }
}
