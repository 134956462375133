



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Resource } from '@/store/modules/resource'
import { EventBus } from '@/utils/event-bus'

@Component
export default class BulkEditResources extends Vue {
  @Prop() resources!: Resource[]

  get haveChanges () {
    return this.resources.filter((r: Resource) => r.hasChanges)
  }

  get allMarktedToDelete () {
    return this.resources.every((r: Resource) => r.toDelete)
  }

  dismissSecs: number = 5
  dismissCountDown: number = 0
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  @Emit('resourcesUpdated')
  resourcesUpdated (hide: boolean = false) {
    this.dismissCountDown = this.dismissSecs
    return hide
  }

  deleteResources () {
    const _undeleteMsg = `Undelete all ${this.resources.length} resources (action must be confirmed via a publish)?`
    const _deleteMsg = `Delete all ${this.resources.length} resources for deletion (action must be confirmed via a publish)?`
    this.$bvModal.msgBoxConfirm(this.allMarktedToDelete ? _undeleteMsg : _deleteMsg, {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.resources.forEach((r: Resource) => {
        r.toDelete = !this.allMarktedToDelete
      })
      EventBus.$emit('show-loader')
      this.$store.dispatch('saveResources', this.resources).then(() => {
        this.resourcesUpdated()
        EventBus.$emit('hide-loader')
      })
    })
  }

  saveChanges () {
    this.$bvModal.msgBoxConfirm(`Are you sure you wish to save changes to all ${this.resources.length} resources?`, {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      this.$store.dispatch('saveResources', this.resources).then(() => {
        this.resourcesUpdated()
        EventBus.$emit('hide-loader')
      })
    })
  }

  publish () {
    this.$bvModal.msgBoxConfirm(`Are you sure you wish to publish all ${this.haveChanges.length} resources?`, {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      this.$store.dispatch('publishResources', this.resources).then(() => {
        this.resourcesUpdated(true)
        EventBus.$emit('hide-loader')
      })
    })
  }
}
