







































































































































































































import axios from 'axios'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Resource, ResourceType, ResourceTag, GvdResourceNumber, SlideDeckResourceNumber } from '@/store/modules/resource'
import { Market } from '@/store/modules/market'
import { Product } from '@/store/modules/product'
import { Gvd, GvdSection, GvdPage } from '@/store/modules/gvd'
import { SlideDeckPage, SlideDeckSection, SlideDeck } from '@/store/modules/slideDeck'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'
import { lookup } from 'mime-types'

@Component
export default class ManageResource extends Vue {
  @Prop() resource!: Resource
  selectedResourceTypeKey: string | null = null
  selectedMarketKeys: string[] = []
  selectedProductKeys: string[] = []
  selectedLinkedToGvdKey: string | null = null
  selectedLinkedToGvdPageKey: string | null = null
  selectedLinkedToSlideDeckKey: string | null = null
  selectedLinkedToSlideDeckPageKey: string | null = null
  selectedResourceTags: string[] = []
  gvdReferenceNumbers: any = {}
  slideDeckReferenceNumbers: any = {}
  file: any = null
  pauseSave: boolean = false
  dismissSecs: number = 5
  dismissCountDown: number = 0
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get resourceTypeOptions () {
    return this.$store.getters.getResourceTypes
  }

  get allResourceTagCategories () {
    return this.$store.getters.getResourceTagCategories
  }

  get allResourceTags () {
    return this.$store.getters.getResourceTags
  }

  getResourceTagsForCategory (categoryKey: string) {
    return this.allResourceTags.filter((resourceTag: ResourceTag) => {
      return resourceTag.resourceTagCategory.key === categoryKey
    })
  }

  get marketOptions () {
    return this.$store.getters.getMarkets
  }

  get productOptions () {
    return this.$store.getters.getProducts
  }

  get selectedGvd () {
    return this.$store.getters.getActiveGvd
  }

  get selectedSlideDeck () {
    return this.$store.getters.getActiveSlideDeck
  }

  get selectedProducts () {
    return this.productOptions.filter((product: Product) => {
      return this.selectedProductKeys.includes(product.key)
    })
  }

  async modalShown () {
    await this.$nextTick()
    this.selectedResourceTypeKey = this.resource.resourceType ? this.resource.resourceType.key : null
    this.selectedResourceTags = this.resource.resourceTags.map((rt: ResourceTag) => {
      return rt.key
    })
    this.selectedMarketKeys = this.resource.markets.map((m: Market) => {
      return m.key
    })
    this.selectedProductKeys = this.resource.products.map((p: Product) => {
      return p.key
    })
    this.selectedLinkedToGvdKey = null
    this.selectedLinkedToGvdPageKey = null
    if (this.resource.gvdPage && this.resource.gvdPage.gvdSection) {
      this.selectedLinkedToGvdKey = this.resource.gvdPage.gvdSection.gvd.key
      this.productSelectForGvdPagesChanged(this.resource.gvdPage.gvdSection.gvd.key)
      this.selectedLinkedToGvdPageKey = this.resource.gvdPage.key
    }
    this.selectedLinkedToSlideDeckKey = null
    this.selectedLinkedToSlideDeckPageKey = null
    if (this.resource.slideDeckPage && this.resource.slideDeckPage.slideDeckSection) {
      this.selectedLinkedToSlideDeckKey = this.resource.slideDeckPage.slideDeckSection.slideDeck.key
      this.productSelectForSlideDeckPagesChanged(this.resource.slideDeckPage.slideDeckSection.slideDeck.key)
      this.selectedLinkedToSlideDeckPageKey = this.resource.slideDeckPage.key
    }

    // gvd and slide deck ref numbers
    this.buildResourceNumbers()
  }

  buildResourceNumbers () {
    this.selectedProducts.forEach((product: Product) => {
      product.gvds.forEach((gvd: Gvd) => {
        const _resNo = this.resource.gvdResourceNumbers.find((resNo: GvdResourceNumber) => {
          return resNo.gvd.key === gvd.key
        })
        this.gvdReferenceNumbers[gvd.key] = _resNo ? _resNo.number : 0
      })
      product.slideDecks.forEach((slideDeck: SlideDeck) => {
        const _resNo = this.resource.slideDeckResourceNumbers.find((resNo: SlideDeckResourceNumber) => {
          return resNo.slideDeck.key === slideDeck.key
        })
        this.slideDeckReferenceNumbers[slideDeck.key] = _resNo ? _resNo.number : 0
      })
    })
  }

  get showGvdPageSelect () {
    return !!this.selectedLinkedToGvdKey
  }

  get showSlideDeckPageSelect () {
    return !!this.selectedLinkedToSlideDeckKey
  }

  resourceTypeChanged (key: string) {
    if (!key) {
      this.resource.resourceType = null
      return
    }
    this.resource.resourceType = this.resourceTypeOptions.find((rt: ResourceType) => {
      return rt.key === key
    })
    this.buildResourceNumbers()
  }

  marketsChanged () {
    this.resource.markets = this.marketOptions.filter((m: Market) => {
      return this.selectedMarketKeys.includes(m.key)
    })
  }

  productsChanged () {
    this.resource.products = this.productOptions.filter((p: Product) => {
      return this.selectedProductKeys.includes(p.key)
    })
    this.buildResourceNumbers()
  }

  resourceTagsChanged () {
    this.resource.resourceTags = this.allResourceTags.filter((rt: ResourceTag) => {
      return this.selectedResourceTags.includes(rt.key)
    })
  }

  productSelectForGvdPagesChanged (key: string) {
    this.selectedLinkedToGvdPageKey = null
    if (!key) {
      return
    }
    this.$store.dispatch('loadGvdAdmin', key)
  }

  gvdPageSelectChanged (key: string) {
    if (!key) {
      return
    }
    const _section = this.selectedGvd.gvdSections.find((section: GvdSection) => {
      return (section.gvdPages as GvdPage[]).some((page: GvdPage) => {
        return page.key === key
      })
    })
    const _page = _section
      ? _section.gvdPages.find((page: GvdPage) => {
        return page.key === key
      })
      : null
    this.resource.gvdPage = _page
  }

  productSelectForSlideDeckPagesChanged (key: string) {
    this.selectedLinkedToSlideDeckPageKey = null
    if (!key) {
      return
    }
    this.$store.dispatch('loadSlideDeckAdmin', key)
  }

  slideDeckPageSelectChanged (key: string) {
    if (!key) {
      return
    }
    const _section = this.selectedSlideDeck.slideDeckSections.find((section: SlideDeckSection) => {
      return (section.slideDeckPages as SlideDeckPage[]).some((page: SlideDeckPage) => {
        return page.key === key
      })
    })
    const _page = _section
      ? _section.slideDeckPages.find((page: SlideDeckPage) => {
        return page.key === key
      })
      : null
    this.resource.slideDeckPage = _page
  }

  fileChosen () {
    this.resource.filename = this.file.name
  }

  clearFile () {
    this.resource.filename = ''
  }

  async uploadFile (resource: Resource) {
    this.pauseSave = true
    const formData = new FormData()
    formData.append(resource.key, this.file)

    await this.$store.dispatch('saveResourceFile', { key: resource.key, formData: formData }).then((resp) => {
      console.log('upload completed')
      this.pauseSave = false
    })
  }

  async downloadFile (resource: Resource) {
    EventBus.$emit('show-loader')
    const resp = await axios.get(`resources/download/${resource.key}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: lookup(resource.filename as string) || undefined })
    saveAs(_blob, resource.filename)
    EventBus.$emit('hide-loader')
  }

  async deleteResource () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to delete?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      await this.$store.dispatch('deleteResource', this.resource)
      this.resourceUpdated()
      EventBus.$emit('hide-loader')
    })
  }

  @Emit('resourceUpdated')
  resourceUpdated (hide: boolean = false) {
    this.dismissCountDown = this.dismissSecs
    return hide ? '' : this.resource.key
  }

  saveChanges () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to save changes?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      // set resource file extension
      this.resource.fileExt = this.resource?.filename?.split(/\.(?=[^.]+$)/)[1]
      this.resource.hasChanges = true
      EventBus.$emit('show-loader')
      this.resource.gvdResourceNumbers = []
      for (const gvdKey in this.gvdReferenceNumbers) {
        const _refNo = this.gvdReferenceNumbers[gvdKey]
        if (_refNo > 0) {
          this.resource.gvdResourceNumbers.push({
            resource: {
              key: this.resource.key
            } as Resource,
            gvd: {
              key: gvdKey
            } as Gvd,
            number: _refNo
          })
        }
      }
      this.resource.slideDeckResourceNumbers = []
      for (const slideDeckKey in this.slideDeckReferenceNumbers) {
        const _refNo = this.slideDeckReferenceNumbers[slideDeckKey]
        if (_refNo > 0) {
          this.resource.slideDeckResourceNumbers.push({
            resource: {
              key: this.resource.key
            } as Resource,
            slideDeck: {
              key: slideDeckKey
            } as SlideDeck,
            number: _refNo
          })
        }
      }
      this.$store.dispatch('saveResource', this.resource).then(() => {
        this.resourceUpdated()
        EventBus.$emit('hide-loader')
      })
    })
  }

  publish () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to publish?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      this.$store.dispatch('publishResource', this.resource).then(() => {
        this.resourceUpdated(true)
        EventBus.$emit('hide-loader')
      })
    })
  }
}
