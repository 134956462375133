





























































































































































































































import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import { Resource } from '@/store/modules/resource'
import { Market } from '@/store/modules/market'
import { Product } from '@/store/modules/product'
import ManageResourceTagsModal from './ManageResourceTags.modal.vue'
import ManageCategoriesModal from './ManageCategories.modal.vue'
import ManageResourceModal from './ManageResource.modal.vue'
import BulkAddResourcesModal from './BulkAddResources.modal.vue'
import BulkEditResourcesModal from './BulkEditResources.modal.vue'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'
import { lookup } from 'mime-types'

@Component({
  components: {
    ManageResourceTagsModal,
    ManageResourceModal,
    BulkAddResourcesModal,
    BulkEditResourcesModal,
    ManageCategoriesModal
  }
})
export default class ResourcesAdminDashboard extends Vue {
  hasPermissions = hasPermissions
  categoriesEnabled: boolean = process.env.ALLOWMANAGECATEGORIES
  currentPage: number = 1
  perPage: number = 40
  totalRows: number = 0

  helpText: string = 'Here you can manage the resources for all products and markets you have permissions for.'

  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      text: 'Manage resources',
      to: { name: 'manage-resources' }
    }
  ]

  tableFields: any[] = [
    {
      key: 'selected',
      label: ''
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'resourceTypeName',
      label: 'Resource type',
      sortable: true,
      class: 'text-nowrap'
    },
    {
      key: 'externalLink',
      label: 'External link',
      sortable: true,
      thClass: 'text-nowrap'
    },
    {
      key: 'filename',
      label: 'Downloadable filename',
      sortable: true,
      thClass: 'text-nowrap'
    },
    {
      key: 'linkedToPageDetails',
      label: 'Page link',
      sortable: true,
      thClass: 'text-nowrap'
    },
    {
      key: 'show_details',
      label: 'Options'
    }
  ]

  get resources () {
    let _resources = JSON.parse(JSON.stringify(this.$store.getters.getAllDraftResources))
    _resources = _resources.map((resource: any) => {
      resource.resourceTypeName = resource.resourceType ? resource.resourceType.name : 'N/A'
      const _links = []
      if (resource.gvdPage && resource.gvdPage.gvdSection && resource.gvdPage.gvdSection.gvd) {
        _links.push(`<strong>${resource.gvdPage.gvdSection.gvd.title}</strong><br>${resource.gvdPage.title}
        (page ${resource.gvdPage.orderIdx + 1} of section ${resource.gvdPage.gvdSection.orderIdx + 1})`)
      }
      if (resource.slideDeckPage && resource.slideDeckPage.slideDeckSection && resource.slideDeckPage.slideDeckSection.slideDeck) {
        _links.push(`<strong>${resource.slideDeckPage.slideDeckSection.slideDeck.title}</strong><br>${resource.slideDeckPage.title}
        (page ${resource.slideDeckPage.orderIdx + 1} of section ${resource.slideDeckPage.slideDeckSection.orderIdx + 1})`)
      }
      resource.linkedToPageDetails = _links.join('<hr>')
      if (resource.markets && resource.markets.length) {
        resource.assignedMarketsString = resource.markets.map((m: any) => {
          return m.name
        }).join(', ')
      }
      if (resource.products && resource.products.length) {
        resource.assignedProductsString = resource.products.map((m: any) => {
          return m.name
        }).join(', ')
      }
      // resource.hasFile = resource.filename && resource.filename.length
      resource._rowVariant = resource.toDelete ? 'danger' : (resource.hasChanges ? 'warning' : null)

      return resource
    })

    // filter by selected resource type
    _resources = _resources.filter((resource: Resource) => {
      const _matchesResourceType = !this.selectedResourceType || (resource.resourceType !== null && resource.resourceType.key === this.selectedResourceType)
      if (_matchesResourceType) {
        return resource
      }
      return null
    })

    // filter by selected market
    _resources = _resources.filter((resource: Resource) => {
      return !this.selectedMarket || resource.markets.some((m: Market) => {
        return m.key === this.selectedMarket
      })
    })

    // filter by selected product
    _resources = _resources.filter((resource: Resource) => {
      return !this.selectedProduct || resource.products.some((p: Product) => {
        return p.key === this.selectedProduct
      })
    })

    _resources = _resources.sort((a: Resource, b: Resource) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
    })

    this.totalRows = _resources.length
    console.log(this.totalRows)
    console.log(_resources.map((e: { name: any }) => e.name))
    return _resources
  }

  $refs!: {
    resourcesTable: HTMLFormElement
  }

  get resourceTypeOptions () {
    const resTypes = this.$store.getters.getResourceTypes.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
    return resTypes
  }

  selectedResourceType: string | null = null

  get marketOptions () {
    const opts = this.$store.getters.getMarkets.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
    return opts
  }

  selectedMarket: string | null = null

  get productOptions () {
    return this.$store.getters.getProducts
  }

  selectedProduct: string | null = null

  activeResource: Resource = this.$store.getters.getDraftResourceByKey()

  async mounted () {
    await this.$nextTick()
    await this.$store.dispatch('loadAllDraftResources')
    await this.$store.dispatch('loadResourceTypes')
    await this.$store.dispatch('loadResourceTagCategories')
    await this.$store.dispatch('loadResourceTags')
    await this.$store.dispatch('loadMarkets')
    await this.$store.dispatch('loadProductsAdminIncDisabled')
    await this.$store.dispatch('loadGvds')
    await this.$store.dispatch('loadSlideDecks')
  }

  selected: Resource[] = []
  activeSelected: Resource[] = []
  allSelected (): boolean {
    return this.selected.length === this.resources.length
  }

  toggleSelectAll () {
    // this.allSelected = !this.allSelected
    if (!this.allSelected()) {
      this.$refs.resourcesTable.selectAllRows()
    } else {
      this.$refs.resourcesTable.clearSelected()
    }
  }

  onRowSelected (items: any[]) {
    this.selected = items
  }

  openBulkAddModal () {
    this.$bvModal.show('bulk-add-resources-modal')
  }

  openBulkEditModal () {
    this.activeSelected = this.selected.map((r: any) => this.$store.getters.getDraftResourceByKey(r.key))
    this.$bvModal.show('bulk-edit-resources-modal')
  }

  openManageResourceModal (key: string) {
    this.activeResource = this.$store.getters.getDraftResourceByKey(key)
    this.$bvModal.show('manage-resource-modal')
  }

  manageResourceModalClosed () {
    this.$store.dispatch('loadAllDraftResources')
  }

  bulkEditResourcesModalClosed () {
    this.$store.dispatch('loadAllDraftResources')
  }

  BulkAddResourcesClosed () {
    this.$store.dispatch('loadAllDraftResources')
  }

  async downloadFile (resource: Resource) {
    EventBus.$emit('show-loader')
    const resp = await axios.get(`resources/download/${resource.key}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: lookup(resource.filename as string) || undefined })
    saveAs(_blob, resource.filename)
    EventBus.$emit('hide-loader')
  }

  async resourceUpdated (resourceKey: string) {
    await this.$store.dispatch('loadAllDraftResources')
    if (resourceKey) {
      this.activeResource = this.$store.getters.getDraftResourceByKey(resourceKey)
    } else {
      this.$bvModal.hide('manage-resource-modal')
    }
  }

  // for bulk changes
  async resourcesUpdated (hide: boolean) {
    const _selected = this.selected
    await this.$store.dispatch('loadAllDraftResources')
    if (hide) {
      this.$bvModal.hide('bulk-edit-resources-modal')
      return
    }
    this.selected = _selected
    for (let i = 0; i < this.resources.length; i++) {
      const _resource = this.resources[i]
      if (this.selected.map((r: Resource) => r.key).includes(_resource.key)) {
        this.$refs.resourcesTable.selectRow(i)
      }
    }
  }

  async resourcesAdded (keys: string[]) {
    await this.$store.dispatch('loadAllDraftResources')
    this.$bvModal.hide('bulk-add-resources-modal')
    for (let i = 0; i < this.resources.length; i++) {
      const _key = this.resources[i].key
      if (keys.includes(_key)) {
        this.$refs.resourcesTable.selectRow(i)
      }
    }
  }

  manageResourceTags () {
    this.$bvModal.show('manage-resource-tags-modal')
  }

  manageCategories () {
    this.$bvModal.show('manage-categories-modal')
  }

  async addNewResource () {
    this.$bvModal.msgBoxConfirm('Create new resource?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const resourceToSave = {
        name: 'New resource',
        description: '',
        resourceType: null,
        markets: [],
        products: [],
        resourceTags: [],
        gvdPages: [],
        slideDeckPages: [],
        isDraft: true
      }
      const newResource: Resource = await this.$store.dispatch('addResource', resourceToSave)
      await this.$store.dispatch('loadAllDraftResources')
      this.activeResource = await this.$store.getters.getDraftResourceByKey(newResource.key)
      this.$bvModal.show('manage-resource-modal')
    })
  }
}
