




































































































import { Component, Vue } from 'vue-property-decorator'
import { ResourceTag, ResourceTagCategory } from '@/store/modules/resource'

@Component
export default class ManageResourceTags extends Vue {
  newCategoryName: string = ''
  newTagName: string = ''

  get allResourceTagCategories () {
    return this.$store.getters.getResourceTagCategories
  }

  get allResourceTags () {
    return this.$store.getters.getResourceTags
  }

  getResourceTagsForCategory (categoryKey: string) {
    return this.allResourceTags.filter((resourceTag: ResourceTag) => {
      return resourceTag.resourceTagCategory.key === categoryKey
    })
  }

  editTagGroup (tagGroup: ResourceTagCategory) {
    tagGroup.edit = !tagGroup.edit
    this.$forceUpdate()
  }

  editTag (tag: ResourceTag) {
    tag.edit = !tag.edit
    this.$forceUpdate()
  }

  saveCategory (updatedTagGroup: ResourceTagCategory) {
    if (!updatedTagGroup.name.length) {
      return
    }
    this.$bvModal.msgBoxConfirm('Save tag group name?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      await this.$store.dispatch('addResourceTagCategory', updatedTagGroup)
      await this.$store.dispatch('loadResourceTagCategories')
    })
  }

  saveTag (tag: ResourceTag) {
    if (!tag.name.length) {
      return
    }
    this.$bvModal.msgBoxConfirm('Save tag?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      await this.$store.dispatch('addResourceTag', tag)
      await this.$store.dispatch('loadResourceTags')
      this.newTagName = ''
    })
  }

  addNewCategory () {
    if (!this.newCategoryName.length) {
      return
    }
    this.$bvModal.msgBoxConfirm('Create new category?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const categoryToSave = {
        name: this.newCategoryName
      }
      await this.$store.dispatch('addResourceTagCategory', categoryToSave)
      await this.$store.dispatch('loadResourceTagCategories')
      this.newCategoryName = ''
    })
  }

  addNewTag (tagCategory: ResourceTagCategory) {
    if (!this.newTagName.length) {
      return
    }
    this.$bvModal.msgBoxConfirm('Create new tag?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const tagToSave = {
        name: this.newTagName,
        resourceTagCategory: tagCategory
      }
      await this.$store.dispatch('addResourceTag', tagToSave)
      await this.$store.dispatch('loadResourceTags')
      this.newTagName = ''
    })
  }

  deleteResourceTagCategory (resourceTagCategory: ResourceTagCategory) {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to delete?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('deleteResourceTagCategory', resourceTagCategory).then(() => {
        this.$store.dispatch('loadResourceTagCategories')
      })
    })
  }

  deleteResourceTag (resourceTag: ResourceTag) {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to delete?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('deleteResourceTag', resourceTag).then(() => {
        this.$store.dispatch('loadResourceTags')
      })
    })
  }

  closeModal () {
    this.$bvModal.hide('manage-resource-tags-modal')
    // this.$bvModal.msgBoxConfirm('Are you sure you wish to save changes?', {
    //   size: 'sm',
    //   buttonSize: 'sm',
    //   okTitle: 'YES',
    //   cancelTitle: 'NO',
    //   footerClass: 'p-2',
    //   hideHeaderClose: false,
    //   centered: true
    // }).then(value => {
    //   if (!value) {
    //     return
    //   }
    //   this.$bvModal.hide('manage-resource-tags-modal')
    // })
  }
}
