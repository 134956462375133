
































































import { ResourceType } from '@/store/modules/resource'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ManageCategories extends Vue {
  newCategoryName: string = '';

  get allCategories () {
    const categories = JSON.parse(JSON.stringify(this.$store.getters.getResourceTypes))
    return categories.sort(function (a: { name: string; }, b: { name: string; }) {
      const x = a.name < b.name ? -1 : 1
      return x
    })
  }

  resetEdit () {
    this.allCategories.forEach((element: { edit: boolean; }) => {
      element.edit = false
    })
  }

  addCategory () {
    if (!this.newCategoryName.length) {
      return
    }
    this.$bvModal
      .msgBoxConfirm('Create new category?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async value => {
        if (!value) {
          return
        }
        const categoryToSave = {
          name: this.newCategoryName
        }
        await this.$store.dispatch('addResourceCategory', categoryToSave)
        await this.$store.dispatch('loadResourceTypes')
        this.newCategoryName = ''
      })
  }

  editCategory (item: any) {
    this.resetEdit()
    item.edit = true
    this.$forceUpdate()
  }

  saveCategory (resourceCategory: ResourceType) {
    this.$bvModal
      .msgBoxConfirm('Save category?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async value => {
        if (!value) {
          return
        }
        await this.$store.dispatch('addResourceCategory', resourceCategory)
        await this.$store.dispatch('loadResourceTypes')
      })
  }

  deleteCategory (resourceCategory: ResourceType) {
    const msg = `Delete "${resourceCategory.name}" category?`
    this.$bvModal
      .msgBoxConfirm(msg, {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async value => {
        this.$store.dispatch('deleteResourceCategory', resourceCategory).then(() => {
          this.$store.dispatch('loadResourceTypes')
        })
      })
  }
}
